<template>
  <v-combobox
    :loading="loading"
    :value="value"
    :items="items"
    :search-input.sync="search"
    cache-items
    label="Firm Member Name"
    item-value="id"
    item-text="name"
    filled
    v-bind="$attrs"
    :rules="[rules.required]"
    @input="$emit('input', $event)"
  />
</template>
<script>
import { debounce } from 'lodash'
import RulesMixin from '@/mixins/RulesMixin'

export default {
  name: 'AttorneyAutocomplete',
  mixins: [RulesMixin],
  props: {
    value: [String, Object],
    lawFirmId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  data: () => ({
    loading: false,
    search: '',
    items: []
  }),
  mounted () {
    this.searchMatches()
  },
  methods: {
    async searchMatches () {
      // start the loading bar
      this.loading = true

      // search for matching attorneys in the system
      let url = '/v1/user/attorneys?name=' + this.search
      if (this.lawFirmId) {
        url += `&organization_id=${this.lawFirmId}`
      }
      const response = await window.axios.get(url)

      // stop the loading bar
      this.loading = false

      this.items = response.data.payload
    }
  },
  watch: {
    search: debounce(function (val) {
      val && val !== this.value && this.searchMatches()
    }, 500)
  }
}
</script>
