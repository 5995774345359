<template>
  <v-container>
    <CreateAppointmentDialog ref="createAppointmentDialog" @reload="getCases"/>
    <DischargeDialog ref="dischargeDialog" @discharge="getCases"/>
    <h1>All Cases</h1>
    <div>
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn small depressed fab color="primary" :to="{ name: 'CreateCase' }" v-on="on">
            <v-icon small>fa-plus</v-icon>
          </v-btn>
        </template>
        <span>create new case</span>
      </v-tooltip>
    </div>
    <div v-if="casesCount === 0" class="mt-2">
      <p>There are currently no active cases matching the search or location</p>
    </div>
    <div>
      <v-data-table :items="cases"
                    :headers="headers"
                    @click:row="goToCase($event.id)"
                    :item-class="rowClasses"
                    :server-items-length="casesCount"
                    @update:options="getCases"
                    :footer-props="{
                      'items-per-page-options': [25, 50, 100]
                    }"
      >
        <template v-slot:top>
          <div class="d-flex mt-2">
            <search-bar v-on:child-method="updateSearchInput" :hint="'When searching DOB use format: YYYY-MM-DD'"/>
            <v-select
                :items="CaseStatusOptions"
                item-text="text"
                item-value="value"
                v-model="caseStatus"
                label="Case Status"
                dense filled
                class="ml-3 CaseStatus"
                hide-details
                clearable
                @change="setCaseStatus"
            />
          </div>
        </template>
        <template #item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template #item.status="{item}">
          {{capitalizeFirstWord(item.status)}}
        </template>
        <template #item.patient_id="{ item }">
          {{ item.patient.first_name }} {{ item.patient.last_name }}
        </template>

        <template #item.location_id="{ item }">
          {{ item.location ? item.location.name : ''}}
        </template>

        <template #item.nextAppointment="{ item }">
          <div v-if="item.appointments.length > 0" class="d-flex">
              <v-icon v-if="item.discharged" color="error">fas fa-exclamation-circle</v-icon>
              <div class="mt-1 ml-1">{{ formatCarbonDateNoSetTime(item.appointments[0].date) }}</div>
          </div>
          <div v-else><v-icon color="warning">fas fa-exclamation-circle</v-icon> no appointment set</div>
        </template>

        <template #item.intake_paperwork_status="{ item }">
          {{ item.intake_paperwork_status ? item.intake_paperwork_status : IntakePaperworkStatus.NOT_SENT }}
        </template>

        <template #item.actions="{ item }" v-if="hasAccess([Roles.ADMIN, Roles.BASIC])">
          <v-menu>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$refs.createAppointmentDialog.open(item.id, item.status)">
                <v-list-item-title>Schedule New Appointment</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="!item.discharged" @click="$refs.dischargeDialog.open(item.id)">
                <v-list-item-title class="red--text">Discharge</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import AddressMixin from '../../mixins/AddressMixin'
import DateMixin from '../../mixins/DateMixin'
import SearchBar from '@/components/FormFields/SearchBar'
import { mapGetters, mapState } from 'vuex'
import { Roles } from '@/js/Roles'
import DischargeDialog from '@/components/Case/DischargeDialog.vue'
import CreateAppointmentDialog from '@/components/Case/Dialogs/Appointments/CreateAppointmentDialog.vue'
import { CaseStatusOptions } from '@/js/CaseStatuses'
import GetCasesMixin from '@/mixins/GetCasesMixin'
import { IntakePaperworkStatus } from '@/js/PatientIntake/PaperworkStatus'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'

export default {
  name: 'ActiveCases',
  components: { DischargeDialog, SearchBar, CreateAppointmentDialog },
  mixins: [AddressMixin, DateMixin, GetCasesMixin],
  data: () => ({
    Roles,
    formatCarbonDateNoSetTime,
    back: false,
    headers: [
      { text: 'Created', value: 'created_at' },
      { text: 'Status', value: 'status' },
      { text: 'Patient Name', value: 'patient_id' },
      { text: 'Location', value: 'location_id' },
      { text: 'Next Appointment', value: 'nextAppointment', sortable: false },
      { text: 'Intake Paperwork Status', value: 'intake_paperwork_status' },
      { text: 'Quick Actions', value: 'actions', sortable: false }
    ],
    filterParams: {
      status: 'active,intake,discharged,billed,settled,collected,bad',
      page: '',
      limit: '',
      orderBy: '',
      orderDir: '',
      patient: true,
      location: true,
      scheduledAppointments: true,
      activeLocationId: '',
      search: ''
    },
    caseStatus: '',
    CaseStatusOptions,
    IntakePaperworkStatus
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl',
      hasAccess: 'User/hasAccess'
    }),
    ...mapState({
      cases: state => state.Case.cases
    })
  },
  mounted () {
    this.getCases()
  },
  methods: {
    rowClasses (item) {
      if (item.dropped) {
        return 'red lighten-5'
      }
    },
    setCaseStatus () {
      if (this.caseStatus) {
        this.filterParams.status = this.caseStatus
      } else {
        this.filterParams.status = 'active,intake,discharged,billed,settled,collected,bad'
      }
      this.getCases()
    }
  }
}
</script>
<style scoped>
.CaseStatus {
  max-width: 35% !important;
}
</style>
