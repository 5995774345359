<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mb-6">
        <PatientSchedule :case-data="caseData" @discharge="$emit('discharge')" @statusChange="$emit('statusChange')" @deletedAppointment="$emit('deletedAppointment')"/>
      </v-col>
      <v-col cols="12" class="referralColor">
        <ReferralsOut :case-data="caseData" @statusChange="$emit('statusChange')" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import PatientSchedule from '@/components/Case/PatientSchedule.vue'
import ReferralsOut from '@/components/Case/ReferralsOut.vue'
export default {
  name: 'Schedule',
  components: { PatientSchedule, ReferralsOut },
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    caseData: {
      required: true,
      type: Object
    }
  },
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>
<style scoped>
.referralColor {
  background-color: #eeeeee;
}
</style>
