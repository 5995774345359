<template>
  <v-combobox
    :loading="loading"
    :value="value"
    :items="items"
    :search-input.sync="search"
    cache-items
    :label="label"
    item-value="id"
    item-text="name"
    filled
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>
<script>
import { debounce } from 'lodash'
import RulesMixin from '@/mixins/RulesMixin'

export default {
  name: 'LawFirmAutocomplete',
  mixins: [RulesMixin],
  props: {
    value: [String, Object],
    label: {
      type: String,
      default: 'Law Firm Name'
    }
  },
  data: () => ({
    loading: false,
    search: '',
    items: []
  }),
  created () {
    this.searchMatches()
  },
  methods: {
    async searchMatches () {
      // start the loading bar
      this.loading = true

      // search for matching attorneys in the system
      const response = await window.axios.get('/v1/organizations/law-firms?name=' + this.search)

      // stop the loading bar
      this.loading = false

      this.items = response.data.payload
    }
  },
  watch: {
    search: debounce(function (val) {
      val && val !== this.value && this.searchMatches()
    }, 500)
  }
}
</script>
