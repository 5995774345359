<template>
  <v-dialog v-model="dialog" max-width="650" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>New Referral</v-card-title>
      <v-card-text>
        <ReferralOutForm :passed-referral="referral" @updated="reassignReferral" ref="referralForm"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="createReferral" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import event, { Events } from '@/event'
import ReferralOutForm from '@/components/Case/Forms/ReferralOutForm.vue'
import { CaseStatuses } from '@/js/CaseStatuses'
export default {
  name: 'CreateReferralOutDialog',
  mixins: [RulesMixin],
  components: { ReferralOutForm },
  props: {
    caseStatus: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    referral: {
      type: '',
      treatment: '',
      body_part: '',
      service_location_id: '',
      service_location_other: '',
      firm_recipients: [],
      date_sent: '',
      note: '',
      day: '',
      time: ''
    },
    loading: false
  }),
  methods: {
    open () {
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.referral = {
        type: '',
        treatment: '',
        body_part: '',
        service_location_id: '',
        service_location_other: '',
        firm_recipients: [],
        date_sent: '',
        note: '',
        appointment_date: '',
        day: '',
        time: ''
      }
      this.$refs.referralForm.reset()
      this.$refs.referralForm.showCreateServiceLocation = false
      this.$nextTick(() => {
        this.$refs.referralForm.$refs.datepicker.clearInput()
        this.$refs.referralForm.$refs.datepicker.checkDate()
        this.$refs.referralForm.$refs.appointmentDatepicker.clearInput()
        this.$refs.referralForm.$refs.appointmentDatepicker.checkDate()
      })
    },
    reassignReferral (value) {
      this.referral = value
    },
    async createReferral () {
      this.loading = true
      this.$refs.referralForm.validate()
      if (!this.$refs.referralForm.formValid || this.$refs.referralForm.dateValid === 'Required' || this.$refs.referralForm.apptDateValid === 'Required') {
        this.loading = false
        return
      }

      try {
        await window.axios.post(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/referrals-out', {
          referral: this.referral
        }).then(async () => {
          await this.checkForTransition()
          event.emit(Events.SUCCESS, 'Referral created successfully', 2000)
          this.$emit('reload')
          this.reset()
          this.dialog = false
        })
      } catch (error) {
        this.loading = false
        if (error.response.data.payload.status === 422) {
          return
        }
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
      this.loading = false
    },
    async checkForTransition () {
      // only want to transition case to active when creating a referral if case is in discharge or billed
      if (![CaseStatuses.DISCHARGED.value, CaseStatuses.BILLED.value].includes(this.caseStatus)) {
        return
      }

      try {
        await this.$store.dispatch('Case/transitionCase', {
          caseId: this.$route.params.caseId,
          transition: CaseStatuses.ACTIVE.value
        })
        this.$emit('statusChange')
      } catch (error) {
        event.emit(Events.ERROR, 'Case could not transition to Active', 2000)
      }
    }
  }
}
</script>
