<template>
  <div>
    <CreateReferralOutDialog
      ref="createReferralDialog"
      @reload="reload"
      @statusChange="$emit('statusChange')"
      :case-status="caseData.status"
    />
    <UpdateReferralOutDialog ref="updateReferralDialog" @reload="reload" />
    <DeleteReferralOutDialog ref="deleteReferralDialog" @reload="reload" />
    <UpdateReferralOutLogDialog ref="updateReferralLogDialog" @reload="reload" />
    <h2 class="mb-3">
      Referrals Out
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn v-if="viewOnly === false"
                 small
                 fab elevation="0"
                 color="primary"
                 v-on="on"
                 class="ml-2"
                 @click="$refs.createReferralDialog.open()"
          >
            <v-icon small>fa-share-square</v-icon>
          </v-btn>
        </template>
        <span>Add Referral</span>
      </v-tooltip>
    </h2>
    <div>
      <v-data-table
        :items="referralsOut"
        :headers="headers"
        :disable-sort="true"
      >
        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$refs.updateReferralDialog.open(item)">
                <v-list-item-title>Update Referral</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="item.status !== 'Canceled'" @click="$refs.deleteReferralDialog.open(item)">
                <v-list-item-title class="red--text">Cancel Referral</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <h2 class="mb-2 mt-5">Referral Logs</h2>
      <v-data-table
        :items="referralLogs"
        :headers="referralLogHeaders"
        @click:row="$refs.updateReferralLogDialog.open($event)"
        :server-items-length="totalItems"
        :page.sync="requestParams.page"
        :disable-sort="true"
        :items-per-page.sync="requestParams.itemsPerPage"
        :footer-props="{
                      'items-per-page-options': [5, 10, 25, 50, 100]
                    }"
      >
        <template #item.created_at="{ item }">
          {{ formatCarbonDate(item.created_at) }}
        </template>
        <template #item.updated_at="{ item }">
          {{ formatCarbonDate(item.updated_at) }}
        </template>
        <template #item.log="{ item }">
          <div class="truncate">
            {{item.log}}
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import CreateReferralOutDialog from '@/components/Case/Dialogs/ReferralsOut/CreateReferralOutDialog.vue'
import UpdateReferralOutDialog from '@/components/Case/Dialogs/ReferralsOut/UpdateReferralOutDialog.vue'
import DeleteReferralOutDialog from '@/components/Case/Dialogs/ReferralsOut/DeleteReferralOutDialog.vue'
import { formatCarbonDateNoSetTime, formatCarbonDate } from '@/js/PatientIntake/functions'
import UpdateReferralOutLogDialog from '@/components/Case/Dialogs/ReferralsOutLog/UpdateReferralOutLogDialog.vue'
export default {
  name: 'ReferralsOut',
  components: {
    CreateReferralOutDialog,
    UpdateReferralOutDialog,
    DeleteReferralOutDialog,
    UpdateReferralOutLogDialog
  },
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    caseData: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    referralsOut: [],
    headers: [
      { text: 'Referral ID', value: 'id' },
      { text: 'Type', value: 'type' },
      { text: 'Treatment', value: 'treatment' },
      { text: 'Body Part', value: 'body_part' },
      { text: 'Status', value: 'status' },
      { text: 'Quick Actions', value: 'actions' }
    ],
    referralLogs: [],
    totalItems: 0,
    referralLogHeaders: [
      { text: 'Referral ID', value: 'referral_id' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Author', value: 'author.name' },
      { text: 'Log', value: 'log' }
    ],
    requestParams: {
      page: 1,
      itemsPerPage: 10
    }
  }),
  computed: {
    ...mapGetters({
      treatmentNameFromId: 'Treatments/treatmentNameFromId'
    })
  },
  mounted () {
    this.reload()
  },
  methods: {
    formatCarbonDate,
    reload () {
      this.getCaseReferralsOut()
      this.getReferralLogs()
    },
    getCaseReferralsOut () {
      this.$store.dispatch('Case/getCaseReferralsOut', this.$route.params.caseId)
        .then(response => {
          this.referralsOut = response.referralsOut
          this.formatReferralDates()
        })
    },
    formatReferralDates () {
      this.referralsOut.map(referral => {
        referral.date_sent = formatCarbonDateNoSetTime(referral.date_sent)
        if (referral.appointment_date) {
          referral.appointment_date = formatCarbonDateNoSetTime(referral.appointment_date)
        }
      })
    },
    getReferralLogs () {
      this.$store.dispatch('Case/getReferralOutLogs', { caseId: this.$route.params.caseId, params: this.requestParams })
        .then(response => {
          this.referralLogs = response.logs
          this.totalItems = response.total
          this.formatReferralDates()
        })
    }
  },
  watch: {
    requestParams: {
      handler: function () {
        this.getReferralLogs()
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.truncate {
  max-width: 17vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
