<style>
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  visibility: visible !important;
  display: none !important;
}
</style>
<template>
  <v-container v-if="caseData">
<!-- Dialogs   -->
    <DeleteCaseDialog ref="deleteCaseDialog" />
    <h1>Case #{{ caseData.id }} {{ caseData.patient.last_name }}, {{ caseData.patient.first_name }}</h1>
    <v-row class="mt-2">
      <v-col class="pb-0 d-flex">
        <h3 class="mr-2">Current Status:</h3>
        <h3 class="font-weight-medium px-4" style="background-color: #f0f0f0;">{{capitalizeFirstWord(caseData.status)}}</h3>
      </v-col>
      <v-col cols="4" class="d-flex justify-end pb-0">
        <v-btn small class="align-self-center" color="error" @click="$refs.deleteCaseDialog.open()">Delete Case</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex">
          <h3 class="mt-2 mr-4">Clinic</h3>
          <v-select
            :items="locations"
            v-model="caseData.location_id"
            item-text="name"
            item-value="id"
            dense filled
            hide-details
            class="clinicNameWidth"
          >
          </v-select>
          <v-btn small class="primary mt-2 ml-4" @click="saveClinic">Save Clinic</v-btn>
          <v-btn v-if="hasAccess([Roles.ADMIN])" small class="primary mt-2 ml-4" @click="editClinic">Edit Clinic</v-btn>
        </div>
        <h3 class="mt-2">Clinic Address</h3>
        <p class="mb-0">
          {{ formatAddress(caseData.location.address) }}
        </p>
      </v-col>
    </v-row>
   <v-row>
     <v-col>
       <v-tabs v-model="tab" >
         <v-tab>Patient Info
           <v-icon v-show="caseData.dropped" right color="error">fas fa-exclamation-circle</v-icon>
         </v-tab>
         <v-tab v-show="hasAccess([Roles.ADMIN, Roles.BASIC])">Schedule
           <v-icon v-show="!hasAppointmentSet" right color="warning">fas fa-exclamation-circle</v-icon>
           <v-icon v-show="hasAppointmentSet && caseData.discharged" right color="error">fas fa-exclamation-circle</v-icon>
         </v-tab>
         <v-tab v-show="hasAccess([Roles.ADMIN, Roles.BASIC])">Logs</v-tab>
         <v-tab v-show="hasAccess([Roles.ADMIN, Roles.BASIC])">Billing</v-tab>
         <v-tab v-show="hasAccess([Roles.ADMIN, Roles.BASIC])">Lien
           <v-icon v-show="!!$refs.lien && ($refs.lien.caseLien === null || $refs.lien.caseLien.status !== 'signed')"
                   right color="warning">
             fas fa-exclamation-circle
           </v-icon>
         </v-tab>
         <v-tab>
           Documents
         </v-tab>
       </v-tabs>

       <v-tabs-items v-model="tab" class="pt-6">
         <v-tab-item>
           <PatientInfo ref="patientInfo" @patientUpdated="getCase" @undropped="getCase"/>
         </v-tab-item>

         <v-tab-item>
           <Schedule ref="schedule" @discharge="getCase" @statusChange="getCase" @deletedAppointment="getCase" :case-data.sync="caseData" />
         </v-tab-item>

         <v-tab-item>
           <case-logs ref="caseLogs" />
         </v-tab-item>

         <v-tab-item>
           <billing-tab ref="billing" :case-data.sync="caseData" @getCase="getCase" @dropped="refreshPatientInfo" />
         </v-tab-item>

         <v-tab-item eager>
           <lien-tab ref="lien" v-if="caseData" :case-data="caseData"/>
         </v-tab-item>

         <v-tab-item>
           <Files @reload="this.getCase" :case-data="caseData" />
         </v-tab-item>
       </v-tabs-items>
     </v-col>
   </v-row>
  </v-container>
</template>
<script>
import CaseLogs from '../../components/Case/CaseLogs'
import LienTab from '../../components/Case/LienTab'
import { DateTime } from 'luxon'
import BillingTab from '../../components/Case/BillingTab'
import Files from '../../components/Case/Files'
import PatientInfo from '../../components/Case/PatientInfo'
import AddressMixin from '../../mixins/AddressMixin'
import PhoneMixin from '../../mixins/PhoneMixin'
import { Roles } from '@/js/Roles'
import { mapActions, mapGetters } from 'vuex'
import { CaseStatusOptions } from '@/js/CaseStatuses'
import DeleteCaseDialog from '@/components/Case/Dialogs/DeleteCaseDialog.vue'
import { capitalizeFirstWord } from '@/js/functions'
import Schedule from '@/components/Case/Schedule.vue'
import event, { Events } from '@/event'
import { LocationTypes } from '@/js/LocationTypes'

export default {
  name: 'CaseView',
  components: { PatientInfo, BillingTab, CaseLogs, LienTab, Schedule, Files, DeleteCaseDialog },
  mixins: [AddressMixin, PhoneMixin],
  props: {
    caseId: {
      type: String
    }
  },
  data: () => ({
    Roles,
    CaseStatusOptions,
    caseData: null,
    hasAppointmentSet: false,
    tab: 0
  }),
  computed: {
    ...mapGetters({
      hasAccess: 'User/hasAccess',
      locations: 'Organization/locations'
    })
  },
  mounted () {
    this.getCase()
    this.getLocations()
  },
  methods: {
    ...mapActions({
      getLocations: 'Organization/getLocations'
    }),
    capitalizeFirstWord,
    async getCase () {
      const response = await this.$store.dispatch('Case/getCase', this.caseId)
      response.appointments.sort((a, b) => {
        return DateTime.fromISO(b.date).valueOf() - DateTime.fromISO(a.date).valueOf()
      })
      this.caseData = response

      // check for next appointment
      const appointments = [...this.caseData.appointments]
      const filtered = appointments
        .filter(appointment => {
          return appointment.status === 'scheduled'
        })

      this.hasAppointmentSet = !!filtered[0]

      // update Case Logs
      if (this.$refs.caseLogs) {
        this.$refs.caseLogs.getCaseLogs()
      }

      if (this.$refs.billing) {
        await this.$refs.billing.getCaseAppointments()
      }
    },
    async saveClinic () {
      this.saving = true
      try {
        await this.$store.dispatch('Case/updateCase', this.caseData)
          .then(() => {
            event.emit(Events.SUCCESS, 'Clinic location updated successfully')
            this.getCase()
          })
          .finally(() => {
            this.saving = false
          })
      } catch (e) {
        console.log(e)
      }
    },
    editClinic () {
      this.$router.push({ name: 'EditLocationView', params: { locationType: LocationTypes.CLINIC.value, locationId: this.caseData.location.id } })
    },
    refreshPatientInfo () {
      this.$refs.patientInfo.getCase()
      this.$refs.patientInfo.getCaseFirmMembers()
    }
  }
}
</script>
<style scoped>
.clinicNameWidth {
  max-width: 20%;
}
</style>
