<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card class="text-center">
      <v-card-title class="d-flex justify-center">Cancel Referral</v-card-title>
      <v-card-text>
        Are you sure you want to cancel this referral?
        <div class="font-weight-medium mt-2">
          <div>ID: {{referral.id}} </div>
          <div>Type: {{referral.type}} </div>
          <div>Treatment: {{referral.treatment}} </div>
          <div>Body Part: {{referral.body_part}} </div>
          <div>Status: {{referral.status}}</div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">No</v-btn>
        <v-btn depressed color="error" @click="deleteReferral">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'DeleteReferralOutDialog',
  mixins: [],
  components: {},
  data: () => ({
    dialog: false,
    referral: {}
  }),
  methods: {
    open (referral) {
      this.referral = structuredClone(referral)
      this.dialog = true
    },
    cancel () {
      this.referral = {}
      this.dialog = false
    },
    async deleteReferral () {
      try {
        await this.$axios.delete(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/referrals-out/' + this.referral.id)
          .then(() => {
            this.referral = {}
            this.dialog = false
            event.emit(Events.SUCCESS, 'Referral canceled successfully.')
            this.$emit('reload')
          })
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    }
  }
}
</script>
