<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>Update Referral Log</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-textarea v-model="referralLog.log" filled placeholder="Log..." :rules="[rules.required]" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="updateReferralLog">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import event, { Events } from '@/event'
import RulesMixin from '@/mixins/RulesMixin'
import structuredClone from '@ungap/structured-clone'
export default {
  name: 'UpdateReferralOutLogDialog',
  mixins: [RulesMixin],
  data: () => ({
    formValid: true,
    dialog: false,
    referralLog: {}
  }),
  methods: {
    open (log) {
      this.referralLog = structuredClone(log)
      this.dialog = true
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.log = {}
      this.formValid = true
      this.$refs.form.reset()
      this.dialog = false
    },
    async updateReferralLog () {
      this.$refs.form.validate()
      if (!this.formValid) {
        return
      }
      await window.axios
        .put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/referrals-out/' + this.referralLog.referral_id + '/logs/' + this.referralLog.id, {
          log: this.referralLog.log
        })
      this.$emit('reload')
      event.emit(Events.SUCCESS, 'Referral Log updated successfully')
      this.reset()
    }
  }
}
</script>
