<template>
  <v-form ref="firmForm" v-model="firmForm">
    <div class="mb-4">
      <h2>Legal Firm Members</h2>
      <small v-if="!validMembers" class="red--text d-block">
        Please make sure each firm member has a name and role assigned
      </small>
      <small class="red--text d-block" :class="{hidden: primaryContact || firm.firm_members.length === 0}">
        Please select a member to be a primary point of contact
      </small>
      <v-row class="mb-2">
        <v-col cols="8">
          <law-firm-autocomplete
            :value="firm.firm_name"
            label="Name of Legal Firm"
            class="my-2"
            @input="handleLawFirmSelection"
            hide-details
            filled dense
            clearable
          />
        </v-col>
        <v-col class="d-flex">
          <v-btn class="primary align-self-center" @click="addFirmMember" :disabled="!firm.firm_name" small>Add Firm Member</v-btn>
        </v-col>
      </v-row>
    </div>
    <div>
      <div v-for="(member, index) in firm.firm_members" :key="index" class="mt-8">
        <div class="font-weight-bold mb-2">Firm Member {{index + 1}}</div>
        <v-row>
          <v-col cols="4" class="pb-0">
            <v-select
              v-model="firm.firm_members[index].role"
              label="* Role"
              :items="MemberOptions"
              item-text="text"
              item-value="value"
              filled dense
              @change="clearMemberInfo(index)"
            />
          </v-col>
          <v-col class="mt-2" cols="6">
            <v-checkbox
              v-model="firm.firm_members[index].primary_contact"
              label="Primary Point of Contact"
              @change="togglePointOfContact(index)"
              dense
            />
          </v-col>
          <v-col class="d-flex justify-center">
            <v-icon class="text-h4 red--text" @click="deleteMember(index, member)">mdi-alpha-x-box</v-icon>
          </v-col>
        </v-row>
        <v-row v-show="firm.firm_members[index].role">
          <v-col cols="12" sm="4" class="pb-0">
            <v-text-field
              v-model="firm.firm_members[index].member_name"
              v-if="firm.firm_members[index].role === Members.CASE_MANAGER.value"
              label="* Full Name"
              :rules="[rules.required]"
              filled dense
            />
            <attorney-autocomplete
              :value="firm.firm_members[index].member_name"
              :lawFirmId="firm.organization_id"
              v-else
              @input="handleFirmMemberSelection(index, $event)"
              clearable dense
            />
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-text-field
              v-model="firm.firm_members[index].member_email"
              type="email"
              label="Email"
              :rules="[rules.optionalEmail]"
              filled dense
            />
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-text-field
              v-model="firm.firm_members[index].member_phone"
              label="Main Phone"
              :rules="[rules.optionalPhone]"
              filled dense
              v-mask="'(###) ###-####'"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-form>
</template>
<script>

import LawFirmAutocomplete from '../FormFields/LawFirmAutocomplete'
import AttorneyAutocomplete from '../FormFields/AttorneyAutocomplete'
import event, { Events } from '@/event'
import { MemberOptions, Members } from '@/js/Members'
import RulesMixin from '@/mixins/RulesMixin'
import { mapGetters } from 'vuex'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'MembersForm',
  components: { LawFirmAutocomplete, AttorneyAutocomplete },
  mixins: [RulesMixin],
  props: {
    passedFirm: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    firmForm: true,
    firm: {
      firm_name: '',
      organization_id: null,
      firm_members: []
    },
    MemberOptions,
    Members,
    validMembers: true
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    }),
    primaryContact () {
      if (this.firm.firm_members.length === 0) {
        return false
      }
      const result = this.firm.firm_members.find(member => member.primary_contact === true || member.primary_contact === 1)
      return result !== undefined
    }
  },
  mounted () {
    this.firm = structuredClone(this.passedFirm)
  },
  methods: {
    validate () {
      this.$refs.firmForm.validate()
      this.validateFirmMembers()
      this.$emit('firm', this.firm)
    },
    handleLawFirmSelection (value) {
      if (!value) {
        this.firm.firm_name = ''
        this.firm.organization_id = ''
        this.firm.firm_members = []
        return
      }
      if (typeof value === 'string') {
        this.firm.firm_name = value
      } else if (typeof value === 'object') {
        this.firm.firm_name = value.name
        this.firm.organization_id = value.id
      }
    },
    handleFirmMemberSelection (index, value) {
      if (!value) {
        this.firm.firm_members[index].user_id = ''
        this.firm.firm_members[index].member_name = ''
        this.firm.firm_members[index].member_email = ''
        this.firm.firm_members[index].member_phone = ''
        return
      }
      if (typeof value === 'string') {
        this.firm.firm_members[index].member_name = value
      } else if (typeof value === 'object') {
        this.firm.firm_members[index].user_id = value.id
        this.firm.firm_members[index].member_name = value.name
        this.firm.firm_members[index].member_email = value.email
        this.firm.firm_members[index].member_phone = value.phone
      }
    },
    deleteMember (index, member) {
      if (member.id) {
        event.emit(Events.CONFIRM, `Are you sure you want to remove ${member.member_name} from the case?
        They will no longer have access to the case.`, () => {
          // delete the firm member
          const url = `${this.url}/case/${this.$route.params.caseId}/members/${member.id}`
          this.$axios.delete(url)
            .then(response => {
              this.$emit('getCase')
              this.firm.firm_members.splice(index, 1)
            })
        })
      } else {
        this.firm.firm_members.splice(index, 1)
      }
    },
    addFirmMember () {
      if (this.firm.firm_members.length === 0) {
        this.firm.firm_members = [{ id: null, user_id: null, role: '', member_name: '', member_email: '', member_phone: '', primary_contact: false }]
      } else {
        this.firm.firm_members.push({ id: null, user_id: null, role: '', member_name: '', member_email: '', member_phone: '', primary_contact: false })
      }
    },
    togglePointOfContact (pocIndex) {
      this.firm.firm_members.map((member, index) => {
        if (member.primary_contact && index !== pocIndex) {
          member.primary_contact = false
        }
      })
    },
    clearMemberInfo (index) {
      this.firm.firm_members[index].user_id = ''
      this.firm.firm_members[index].member_name = ''
      this.firm.firm_members[index].member_email = ''
      this.firm.firm_members[index].member_phone = ''
    },
    validateFirmMembers () {
      this.validMembers = true
      this.firm.firm_members.forEach(member => {
        if (member.member_name === '' || !member.role === '') {
          this.validMembers = false
        }
      })
    }
  },
  updated () {
    this.$emit('firm', this.firm)
  }
}
</script>
<style>
.hidden {
  visibility: hidden;
}
</style>
