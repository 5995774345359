var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('CreateAppointmentDialog',{ref:"createAppointmentDialog",on:{"reload":_vm.getCases}}),_c('DischargeDialog',{ref:"dischargeDialog",on:{"discharge":_vm.getCases}}),_c('h1',[_vm._v("All Cases")]),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","fab":"","color":"primary","to":{ name: 'CreateCase' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-plus")])],1)]}}])},[_c('span',[_vm._v("create new case")])])],1),(_vm.casesCount === 0)?_c('div',{staticClass:"mt-2"},[_c('p',[_vm._v("There are currently no active cases matching the search or location")])]):_vm._e(),_c('div',[_c('v-data-table',{attrs:{"items":_vm.cases,"headers":_vm.headers,"item-class":_vm.rowClasses,"server-items-length":_vm.casesCount,"footer-props":{
                    'items-per-page-options': [25, 50, 100]
                  }},on:{"click:row":function($event){return _vm.goToCase($event.id)},"update:options":_vm.getCases},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex mt-2"},[_c('search-bar',{attrs:{"hint":'When searching DOB use format: YYYY-MM-DD'},on:{"child-method":_vm.updateSearchInput}}),_c('v-select',{staticClass:"ml-3 CaseStatus",attrs:{"items":_vm.CaseStatusOptions,"item-text":"text","item-value":"value","label":"Case Status","dense":"","filled":"","hide-details":"","clearable":""},on:{"change":_vm.setCaseStatus},model:{value:(_vm.caseStatus),callback:function ($$v) {_vm.caseStatus=$$v},expression:"caseStatus"}})],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstWord(item.status))+" ")]}},{key:"item.patient_id",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+" ")]}},{key:"item.location_id",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.location ? item.location.name : '')+" ")]}},{key:"item.nextAppointment",fn:function(ref){
                  var item = ref.item;
return [(item.appointments.length > 0)?_c('div',{staticClass:"d-flex"},[(item.discharged)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fas fa-exclamation-circle")]):_vm._e(),_c('div',{staticClass:"mt-1 ml-1"},[_vm._v(_vm._s(_vm.formatCarbonDateNoSetTime(item.appointments[0].date)))])],1):_c('div',[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("fas fa-exclamation-circle")]),_vm._v(" no appointment set")],1)]}},{key:"item.intake_paperwork_status",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.intake_paperwork_status ? item.intake_paperwork_status : _vm.IntakePaperworkStatus.NOT_SENT)+" ")]}},(_vm.hasAccess([_vm.Roles.ADMIN, _vm.Roles.BASIC]))?{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$refs.createAppointmentDialog.open(item.id, item.status)}}},[_c('v-list-item-title',[_vm._v("Schedule New Appointment")])],1),(!item.discharged)?_c('v-list-item',{on:{"click":function($event){return _vm.$refs.dischargeDialog.open(item.id)}}},[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Discharge")])],1):_vm._e()],1)],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }